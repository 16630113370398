@import "../styles/mixins";

.headerWrapper {
    background-color: rgba(246, 246, 246, 1);

    .header {
        padding-top: 32px;
        padding-bottom: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .downloadCTA {
            @include mobile() {
                display: none;
            }
        }
    }
}

.title {
    font-size: 26px;
    font-family: met_bold;
    margin: 0;

    @include mobile() {
        font-size: 20px;
    }
}
.desc {
    font-size: 22px;

    @include mobile() {
        font-size: 16px;
    }
}

.sec1 {
    padding-top: 72px;
    padding-bottom: 72px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile() {
        padding-top: 32px;
        padding-bottom: 32px;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .details {
        flex: 1;
        max-width: 528px;

        @include mobile() {
            order: 2;
            margin-top: 32px;
        }

        .pageTitle {
            font-family: met_bold;
            font-size: 60px;

            @include mobile() {
                font-size: 32px;
            }
        }

        .storeImagesWrapper {
            & > * {
                margin-top: 80px;
                margin-right: 32px;

                @include mobile() {
                    margin-top: 32px;

                    &:nth-of-type(n+2) {
                        margin-top: 20px;
                    }
                }
            }

            .storeImg {
                width: 220px;
                cursor: pointer;

                @include mobile() {
                    display: block;
                    width: 100%;
                    max-width: 240px;
                }
            }
        }
    }

    .img {
        width: 450px;

        @include mobile() {
            width: 100%;
            order: 1;
        }
    }
}

.sec2Wrapper {
    background-color: rgba(246, 246, 246, 1);

    .sec2 {
        padding-top: 32px;
        padding-bottom: 32px;

        .flagsAnimationContainer {
            overflow: hidden;
            position: relative;
            height: 45px;

            .flagsAnimation {
                position: absolute;
                -webkit-animation: linear infinite;
                -webkit-animation-name: run;
                -webkit-animation-duration: 200s;
                display: flex;
                justify-content: center;

                img {
                    height: 45px;
                }
            }
        }
    }
}

.sec3Wrapper {
    .sec3 {
        padding-top: 96px;
        padding-bottom: 96px;

        @include mobile() {
            padding-top: 76px;
            padding-bottom: 76px;
        }

        .welcomeToText {
            position: absolute;
            top: 50%;
            left: 100px;
            transform: translateY(-50%);
            color: var(--bgColorPrimary);
        }

        .usageWidgetRow {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: $spaceL3;

            @include mobile() {
                grid-template-columns: repeat(1, 1fr);
            }

            .usageWidget {
                background: #F7F7F7;
                border-radius: 25px;
                padding: 32px;
                flex: 1;
                align-self: stretch;

                .img {
                    height: 200px;

                    @include mobile() {
                        height: 140px;
                    }
                }
                .title {
                    margin-top: 32px;
                }
                .desc {
                    margin-top: 8px;
                }
            }
        }

        .upiFeaturesContainerWrapper {
            display: flex;
            justify-content: flex-start;

            @include mobile() {
                overflow-x: auto;
            }

            .upiFeaturesContainer {
                display: flex;

                @include mobile() {
                    min-width: calc(250% + 64px);
                }

                .upiFeatureWidget {
                    background: #F7F7F7;
                    border-radius: 25px;
                    padding: 32px;
                    flex: 1;

                    &:nth-of-type(n+2) {
                        margin-left: 32px;
                    }

                    .featureImg {
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        width: 100%;
                        padding-top: 100%;
                        border-radius: 25px;
                    }
                }
            }
        }

        .comparisonContainer {
            background: #F7F7F7;
            border-radius: 25px;
            padding-left: 32px;
            padding-right: 32px;

            @include mobile() {
                padding-left: 16px;
                padding-right: 16px;
            }

            .comparisonRow {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 90px;
                padding-top: 5px;
                padding-bottom: 5px;

                @include mobile() {
                    min-height: 70px;
                }

                &:nth-of-type(1) {
                    .col1 {
                        font-family: met_medium;
                    }
                }

                &:nth-of-type(n+2) {
                    border-top: 1px solid rgba(0, 0, 0, 0.05);
                }

                & > *:not(.col1) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .col1 {
                    flex: 1;
                    @extend .desc;
                }

                .col2,
                .col3 {
                    width: 90px;
                    font-size: 14px;

                    @include mobile() {
                        width: 40px;
                        font-size: 10px;
                    }
                }

                .col4 {
                    width: 110px;
                    font-size: 14px;

                    @include mobile() {
                        width: 70px;
                        font-size: 10px;
                    }
                }
            }
        }

        .safeMoneyContainer {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 110px;

            @include mobile() {
                flex-flow: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 60px;
            }

            .details {
                flex: 1;
                max-width: 540px;
            }

            .img {
                width: 100%;
                max-width: 500px;

                @include mobile() {
                    display: none;
                }
            }
        }
    }
}

.sec4Wrapper {
    background-color: rgba(246, 246, 246, 1);

    .sec4 {
        padding-top: 96px;
        padding-bottom: 96px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile() {
            padding-top: 32px;
            padding-bottom: 32px;

            .sectionHeading {
                font-size: 18px !important;
            }
        }

        .imgsWrapper {
            display: flex;
            justify-content: center;
            align-self: center;

            img {
                height: 48px;

                &:nth-of-type(n+2) {
                    margin-left: 56px;
                }
            }
        }

        @include mobile() {
            flex-flow: column;
            justify-content: center;

            .imgsWrapper {
                margin-top: 32px;

                img {
                    height: 32px;

                    &:nth-of-type(n+2) {
                        margin-left: 32px;
                    }
                }
            }
        }
    }
}

.sec5Wrapper {
    .sec5 {
        padding-top: 64px;
        padding-bottom: 64px;

        @include mobile() {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .sec5Widget {
            color: #fff;
            background: #282828;
            border-radius: 25px;
            padding: 65px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @include mobile() {
                padding: 32px 16px;
                flex-flow: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .imgWrapper {
                flex: 1;

                img {
                    display: block;
                    width: 100%;
                    max-width: 390px;
                }
            }

            .details {
                flex: 1;
                margin-left: 32px;

                @include mobile() {
                    margin-left: 0;
                    margin-top: 32px;
                    text-align: center;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                }

                .desc {
                    color: rgba(246, 246, 246, 0.6);
                }

                .acrossCountryListWrapper {
                    overflow: hidden;
                    position: relative;
                    height: 48px;
                    width: 100%;
                    //border-radius: 16px;
                    background-color: rgba(247, 247, 247, 0.1);
                    padding: 0 16px;

                    @include mobile() {
                        margin-left: -16px;
                        margin-right: -16px;
                        width: calc(100% + 32px);
                    }

                    .acrossCountryList {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        display: flex;
                        justify-content: flex-start;
                        -webkit-animation: linear infinite;
                        -webkit-animation-name: acrossCountryRun;
                        -webkit-animation-duration: 1400s;
                        min-width: 100000px;
                        padding-left: 20px;
                        padding-right: 20px;

                        & > * {
                            position: relative;
                            padding-left: 32px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            @include mobile() {
                                padding-left: 16px;
                            }

                            &:nth-of-type(n+2) {
                                margin-left: 16px;
                            }

                            &:before {
                                content: ' ';
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 24px;
                                height: 24px;
                                background-color: #D9D9D9;
                                border-radius: 50%;

                                @include mobile() {
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                        }
                    }
                }

                .desc {
                    @include mobile() {
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}

.sec6Wrapper {
    background-color: rgba(246, 246, 246, 1);

    .sec6 {
        padding-top: 116px;
        padding-bottom: 116px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include mobile() {
            padding-top: 64px;
            padding-bottom: 64px;
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .imgWrapper {
            flex: 1;

            img {
                display: block;
                max-width: 450px;
            }
        }

        .details {
            flex: 1;
            margin-left: 32px;

            @include mobile() {
                margin-left: 0;
                margin-top: 32px;
            }

            .widget {
                background: #FFFFFF;
                border-radius: 25px;
                padding: 32px;

                &:nth-of-type(n+2) {
                    margin-top: 32px;
                }
            }
        }
    }
}

.faqWrapper {
    .faqContainer {
        padding-top: 96px;
        padding-bottom: 96px;

        @include mobile() {
            padding-top: 64px;
            padding-bottom: 64px;
        }
    }
}

.faqWidget {
    background: #F7F7F7;
    border-radius: 12px;
    padding: 32px;

    @include mobile() {
        padding: 16px;
    }
}

.supportContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #F7F7F7;
    border-radius: 25px;
    padding: 32px;

    @include mobile() {
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        & > *:nth-of-type(n+2) {
            margin-top: 32px;
        }
    }

    .details {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include mobile() {
            justify-content: center;
            align-items: center;
        }
    }
}

.footerWrapper {
    color: #fff;
    background-color: rgba(40, 40, 40, 1);
    padding-top: 108px;
    padding-bottom: 108px;

    @include mobile() {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include mobile() {
            flex-flow: column;

            & > *:nth-of-type(n+2) {
                margin-top: 48px;
            }
        }

        .footerLink {
            position: relative;

            &:hover {
                text-decoration: underline;
            }

            &:before {
                content: '- ';
                color: var(--colorSecondary);
                position: absolute;
                left: -15px;
            }
        }
    }
}


@keyframes run {
    0% {
        left: 0;
    }
    100% {
        left: -10000px;
    }
}


@keyframes acrossCountryRun {
    0% {
        left: 0;
    }
    100% {
        left: -90000px;
    }
}


